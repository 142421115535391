
import Vue from 'vue'
import { sendAdClick } from '~/assets/ts/utils/misc'
import { AdTrackerUrl, featureLink } from '~/assets/ts/utils/urls'
import Poller from '~/components/_general/Poller.vue'
import SiteAdTrackerImage from '~/components/site/AdTrackerImage.vue'
import BackgroundImage from '~/components/_general/BackgroundImage.vue'
import SiteLayoutWithSideContent from '~/components/site/layout/WithSideContent.vue'
import SiteHomeHeroSide from '~/components/site/home/HeroSide.vue'
import { GetAdTypeApi } from '~/assets/ts/utils/promotions'
import { AdType, DisplayAdApi } from '~/apiclient/apiads'
import { randomInArray } from '~/assets/ts/utils/lists'
import SiteCarouselArrow from '~/components/site/CarouselArrow.vue'

export default Vue.extend({
  name: 'SiteHomeHero',
  components: {
    SiteCarouselArrow,
    SiteHomeHeroSide,
    SiteLayoutWithSideContent,
    BackgroundImage,
    SiteAdTrackerImage,
    Poller,
  },
  data() {
    return {
      active: 0,
      paused: false,
      pauseTimeout: 0,
      mounted: false,
    }
  },
  computed: {
    textTransitionDurationClass(): string {
      return 'duration-500'
    },
    changeFrequency(): number {
      return 7000 // ms
    },
    maxPausedDuration(): number {
      return 10000 // ms
    },
    items(): (DisplayAdApi | undefined)[] {
      const displayAds = this.$store.getters['site/displayAds'].filter(
        (a: DisplayAdApi) => a.adType === GetAdTypeApi(AdType.Hero)
      )
      return displayAds.length ? displayAds : [undefined]
    },
    transitionDuration(): string {
      return this.paused
        ? this.textTransitionDurationClass
        : 'duration-[2000ms]'
    },
  },
  mounted() {
    this.mounted = true
  },
  destroyed() {
    this.clearTimeout()
  },
  methods: {
    switchTo(index: number) {
      this.active = index
    },
    key(index: number, location: string, additionalIndex?: number) {
      return `${index}-${location}${
        additionalIndex !== undefined ? `-${additionalIndex}` : ''
      }`
    },
    clearTimeout() {
      window.clearTimeout(this.pauseTimeout)
    },
    unpauseTimer() {
      this.clearTimeout()
      this.paused = false
    },
    pauseTimer() {
      this.paused = true
      this.clearTimeout()
      this.pauseTimeout = window.setTimeout(() => {
        this.unpauseTimer()
      }, this.maxPausedDuration)
    },
    setNextGraphic() {
      if (this.active === this.items.length - 1) {
        this.active = 0
      } else {
        this.active += 1
      }
    },
    setLastGraphic() {
      if (this.active === 0) {
        this.active = this.items.length - 1
      } else {
        this.active -= 1
      }
    },
    actionTo(ad: DisplayAdApi | undefined) {
      if (!ad) return undefined
      const link = featureLink(ad)
      return link.isSiteUrl ? link.url : undefined
    },
    actionUrl(ad: DisplayAdApi | undefined) {
      if (!ad) return undefined
      const link = featureLink(ad)
      return link.isSiteUrl ? undefined : link.url
    },
    heroGraphicUrl(ad: DisplayAdApi | undefined) {
      if (!ad || !ad.images) return ''
      return randomInArray(ad.images).imageURLWebp
    },
    heroItemClicked() {
      const item = this.items[this.active]
      if (item === undefined) return
      sendAdClick(AdTrackerUrl(item.campaignID, false))
    },
  },
})
